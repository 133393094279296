import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import useSWR from 'swr';
import { CircularProgress } from '@mui/material';

export default function TurnoverInfo({ userId }) {
  const { t } = useTranslation();
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const {
    data,
    isLoading,
  } = useSWR(`turnoverInfo/${userId}`, () => {
    return feathers.service('turnover-info').get(userId);
  }, {
    revalidateOnFocus: true,
    refreshInterval: 30000,
  });

  useEffect(() => {
    if (data?.error) {
      setGlobalErrorMessage(data.error.message);
    }
  }, [data, setGlobalErrorMessage]);

  return (
    <Paper sx={{ p: 2, m: 1 }} elevation={10}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 700 }} variant='h6'>
          {t('Turnover Information')}
        </Typography>
        <span>{isLoading && <CircularProgress size={16} sx={{ ml: 1 }} />}</span>
      </Box>
      {
        /*
         <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: savedBank.accountNumber ? 'inherit' : 'text.disabled',
          fontStyle: savedBank.accountNumber ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Account Number')}</span>
        <span>{formattedAccountNumber || t('TBD')}</span>
      </Typography>
        */
      }
      <Typography sx={{
        display: 'flex',
        justifyContent: 'space-between',
        color: data?.depositTurnoverRequirement ? 'inherit' : 'text.disabled',
        fontStyle: data?.depositTurnoverRequirement ? 'inherit' : 'oblique',
      }} variant='subtitle1'>
        <span>{t('Deposit T-o Requirement')}</span>
        <span>{data?.depositTurnoverRequirement?.toFixed(2) || t('TBD')}</span>
      </Typography>
      <Typography sx={{
        display: 'flex',
        justifyContent: 'space-between',
        color: data?.redeemedBonusTurnoverRequirement ? 'inherit' : 'text.disabled',
        fontStyle: data?.redeemedBonusTurnoverRequirement ? 'inherit' : 'oblique',
      }} variant='subtitle1'>
        <span>{t('Redeemed Bonus T-o Requirement')}</span>
        <span>{data?.redeemedBonusTurnoverRequirement?.toFixed(2) || t('TBD')}</span>
      </Typography>
      <Typography sx={{
        display: 'flex',
        justifyContent: 'space-between',
        color: data?.baseBonusTurnoverRequirement ? 'inherit' : 'text.disabled',
        fontStyle: data?.baseBonusTurnoverRequirement ? 'inherit' : 'oblique',
      }} variant='subtitle1'>
        <span>{t('Base Bonus T-o Requirement')}</span>
        <span>{data?.baseBonusTurnoverRequirement?.toFixed(2) || t('TBD')}</span>
      </Typography>
      <Typography sx={{
        display: 'flex',
        justifyContent: 'space-between',
        color: data?.totalTurnoverRequirement ? 'inherit' : 'text.disabled',
        fontStyle: data?.totalTurnoverRequirement ? 'inherit' : 'oblique',
      }} variant='subtitle1'>
        <span>{t('Total T-o Requirement')}</span>
        <span>{data?.totalTurnoverRequirement?.toFixed(2) || t('TBD')}</span>
      </Typography>
      <Typography sx={{
        display: 'flex',
        justifyContent: 'space-between',
        color: data?.currentTurnover ? 'inherit' : 'text.disabled',
        fontStyle: data?.currentTurnover ? 'inherit' : 'oblique',
      }} variant='subtitle1'>
        <span>{t('Current T-o')}</span>
        <span>{data?.currentTurnover?.toFixed(2) || t('TBD')}</span>
      </Typography>
      <Typography sx={{
        display: 'flex',
        justifyContent: 'space-between',
        color: data?.completedTurnoverPercentage ? 'inherit' : 'text.disabled',
        fontStyle: data?.completedTurnoverPercentage ? 'inherit' : 'oblique',
      }} variant='subtitle1'>
        <span>{t('Completed T-o Percentage')}</span>
        <span>{data?.completedTurnoverPercentage?.toFixed(2) || t('TBD')}</span>
      </Typography>
    </Paper>
  );
}
