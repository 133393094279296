import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ListSubheader from '@mui/material/ListSubheader';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from 'hooks/useAuth';
import { transformSavedData } from 'utils/form-utils';
import { getLocaleYupObject } from 'utils/yup-helper';
import { gameTypeMenu } from 'utils/menu';
import {
  FormikTextField,
  FormikDateTimePicker,
  FormikSwitch,
  FormikSelect,
} from 'features/formikControl';
import dayjs from 'dayjs';

const CASL_SUBJECT = 'baseBonusSettings';
const SERVICE_NAME = 'base-bonus-settings';

export default function Form(props) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { supportedLocales = ['en'] } = user || {};
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        minDeposit: 30,
        maxDeposit: 0,
        maxBonus: 500,
        wageringContributionConfig: {
          slot: 1,
          live: 0,
          fishing: 0,
          other: 0,
        },
        startsAt: dayjs().add(1, 'day').startOf('day').toDate(),
        endsAt: dayjs().add(1, 'day').add(3, 'year').startOf('day').toDate(),
        isEnabled: true,
      }
    }, [propData, savedData]
  );

  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    group: Yup.string().required(t("Required")),
    groupLabel: Yup.object().shape(getLocaleYupObject(supportedLocales, false)),
    name: Yup.object().shape(getLocaleYupObject(supportedLocales, true, t("Required"))),
    weight: Yup.number().required(t("Required")).min(0),
    amount: Yup.number().required(t("Required")).min(0.01),
    amountType: Yup.string().matches(/fixed|percentage/).required(t("Required")),
    minDeposit: Yup.number().min(0),
    maxDeposit: Yup.number().min(0),
    maxBonus: Yup.number().min(0),
    availabilityType: Yup.string().matches(/on-nth-deposit|every-n-deposit/).required(t("Required")),
    availabilityInterval: Yup.string().matches(/global|hour|day|isoWeek|month|year/).required(t("Required")),
    availabilityValue: Yup.number().integer().required(t("Required")).min(1),
    claimLimit: Yup.number().integer().min(1),
    claimLimitResetPolicy: Yup.string().matches(/hour|day|isoWeek|month|year/),
    wageringMultiplier: Yup.number().required(t("Required")).min(1),
    wageringContributionPolicy: Yup.string().matches(/sum|min|max/).required(t("Required")),
    wageringContributionConfig: Yup.object().shape({
      slot: Yup.number().required(t("Required")).min(0),
      live: Yup.number().required(t("Required")).min(0),
      fishing: Yup.number().required(t("Required")).min(0),
      other: Yup.number().required(t("Required")).min(0),
    }),
    gameAllowedPolicy: Yup.string().matches(/all|include|exclude/).required(t("Required")),
    gameFilters: Yup.array().of(Yup.string()),
    startsAt: Yup.date().required(t("Required")),
    endsAt: Yup.date().required(t("Required")),
    isEnabled: Yup.bool().required(t("Required")),
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('Base Bonus Setting')}</DialogTitle>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...data,
          caslSubject: CASL_SUBJECT,
          serviceName: SERVICE_NAME,
        }}
        validationSchema={dataSchema}
        onSubmit={async values => {
          try {
            const _id = get(data, '_id');

            if (!_id) {
              const { _id, ...newValues } = values;
              const saved = await feathers.service(SERVICE_NAME).create(newValues);
              setSavedData(saved);
            } else {
              await feathers.service(SERVICE_NAME).patch(_id, {
                $set: values
              });
            }
            setGlobalMessage({
              message: t(`Saved`),
              severity: 'success'
            });
          } catch (err) {
            setGlobalErrorMessage({ err });
          }
        }}
      >
        {
          ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <>
              <DialogContent dividers>
              <Paper sx={{ p: 2 }} elevation={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='group'
                      name='group'
                      label={t('Group')}
                      value={get(values, 'group', '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Paper sx={{ p: 2 }} elevation={1}>
                    <Typography variant='subtitle2' sx={{ pb: 2 }}>{t('Group Label')}</Typography>
                      <Grid container spacing={2}>
                      {
                        supportedLocales.map(locale => {
                          const groupLabelKey = `groupLabel.${locale}`;
                          return (
                            <Grid item xs={6} key={locale}>
                              <FormikTextField
                                fullWidth
                                id={groupLabelKey}
                                name={groupLabelKey}
                                label={t(`locale.${locale}`)}
                                value={get(values, groupLabelKey, '')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Grid>
                          );
                        })
                      }
                      </Grid>
                      <Typography variant='subtitle2' sx={{ pt: 2, color: 'red', fontStyle: 'italic' }}>
                        {t('Group Label is needed for the first item in the group')}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper sx={{ p: 2 }} elevation={1}>
                      <Typography variant='subtitle2' sx={{ pb: 2 }}>{t('Name')}</Typography>
                      <Grid container spacing={2}>
                        {
                          supportedLocales.map(locale => {
                            const nameKey = `name.${locale}`;
                            return (
                              <Grid item xs={6} key={locale}>
                                <FormikTextField
                                  fullWidth
                                  id={nameKey}
                                  name={nameKey}
                                  label={t(`locale.${locale}`)}
                                  value={get(values, nameKey, '')}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='weight'
                      name='weight'
                      label={t('Weight')}
                      value={get(values, 'weight', '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='amount'
                      name='amount'
                      label={t('Amount')}
                      value={get(values, 'amount', '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSelect
                      fullWidth
                      select
                      defaultValue={'percentage'}
                      id='amountType'
                      name='amountType'
                      label={t('Amount Type')}
                      value={get(values, 'amountType', 'percentage')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {
                        ['fixed', 'percentage'].map(o => (
                          <MenuItem key={o} value={o}>
                            {t(`amountType.${o}`)}
                          </MenuItem>
                        ))
                      }
                    </FormikSelect>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='minDeposit'
                      name='minDeposit'
                      label={t('Min Deposit')}
                      value={get(values, 'minDeposit', '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='maxDeposit'
                      name='maxDeposit'
                      label={t('Max Deposit')}
                      value={get(values, 'maxDeposit', '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='maxBonus'
                      name='maxBonus'
                      label={t('Max Bonus')}
                      value={get(values, 'maxBonus', '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSelect
                      fullWidth
                      select
                      defaultValue={'on-nth-deposit'}
                      id='availabilityType'
                      name='availabilityType'
                      label={t('Availability Type')}
                      value={get(values, 'availabilityType', 'on-nth-deposit')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {
                        ['on-nth-deposit', 'every-n-deposit'].map(o => (
                          <MenuItem key={o} value={o}>
                            {t(`bonusAvailabilityType.${o}`)}
                          </MenuItem>
                        ))
                      }
                    </FormikSelect>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSelect
                      fullWidth
                      select
                      defaultValue={'global'}
                      id='availabilityInterval'
                      name='availabilityInterval'
                      label={t('Availability Interval')}
                      value={get(values, 'availabilityInterval', 'global')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {
                        ['global', 'hour', 'day', 'isoWeek', 'month', 'year'].map(o => (
                          <MenuItem key={o} value={o}>
                            {t(`bonusAvailabilityInterval.${o}`)}
                          </MenuItem>
                        ))
                      }
                    </FormikSelect>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='availabilityValue'
                      name='availabilityValue'
                      label={t('Availability Value')}
                      value={get(values, 'availabilityValue', '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='claimLimit'
                      name='claimLimit'
                      label={t('Claim Limit')}
                      value={get(values, 'claimLimit', '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSelect
                      fullWidth
                      select
                      defaultValue={'day'}
                      id='claimLimitResetPolicy'
                      name='claimLimitResetPolicy'
                      label={t('Claim Limit Reset Policy')}
                      value={get(values, 'claimLimitResetPolicy', 'day')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {
                        ['hour', 'day', 'isoWeek', 'month', 'year'].map(o => (
                          <MenuItem key={o} value={o}>
                            {t(`bonusAvailabilityInterval.${o}`)}
                          </MenuItem>
                        ))
                      }
                    </FormikSelect>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='wageringMultiplier'
                      name='wageringMultiplier'
                      label={t('Wagering Multiplier')}
                      value={get(values, 'wageringMultiplier', '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSelect
                      fullWidth
                      select
                      defaultValue={'max'}
                      id='wageringContributionPolicy'
                      name='wageringContributionPolicy'
                      label={t('Wagering Contribution Policy')}
                      value={get(values, 'wageringContributionPolicy', 'max')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {
                        ['sum', 'min', 'max'].map(o => (
                          <MenuItem key={o} value={o}>
                            {t(`wageringContributionPolicy.${o}`)}
                          </MenuItem>
                        ))
                      }
                    </FormikSelect>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper sx={{ p: 2 }} elevation={1}>
                      <Typography variant='subtitle2'>{t('Wagering Contribution Config')}</Typography>
                      <Grid container spacing={2}>
                      {
                        ['slot', 'live', 'fishing', 'other'].map((key) => (
                          <Grid item xs={3} key={key}>
                          <FormikTextField
                            margin='normal'
                            key={key}
                            fullWidth
                            id={`wageringContributionConfig.${key}`}
                            name={`wageringContributionConfig.${key}`}
                            label={t(`gameCategories.${key}`)}
                            value={get(values, `wageringContributionConfig.${key}`, '0')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          </Grid>
                        ))
                      }
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSelect
                      fullWidth
                      select
                      defaultValue={'all'}
                      id='gameAllowedPolicy'
                      name='gameAllowedPolicy'
                      label={t('Game Allowed Policy')}
                      value={get(values, 'gameAllowedPolicy', 'all')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {
                        ['all', 'include', 'exclude'].map(o => (
                          <MenuItem key={o} value={o}>
                            {t(`gameAllowedPolicy.${o}`)}
                          </MenuItem>
                        ))
                      }
                    </FormikSelect>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSelect
                      fullWidth
                      select
                      multiple
                      id='gameFilters'
                      name='gameFilters'
                      label={t('Game Filters')}
                      value={get(values, 'gameFilters', [])}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {
                        gameTypeMenu(true, true, true).map((g) => {
                          if (g.type === 'subheader') {
                            return <ListSubheader key={g.key}>{t(g.value)}</ListSubheader>
                          }
                          return <MenuItem key={g.key} value={g.key}>{g.value}</MenuItem>
                        })
                      }
                    </FormikSelect>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikDateTimePicker
                      fullWidth
                      id='startsAt'
                      name='startsAt'
                      label={t('Starts At')}
                      value={get(values, 'startsAt', null)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikDateTimePicker
                      fullWidth
                      id='endsAt'
                      name='endsAt'
                      label={t('Ends At')}
                      value={get(values, 'endsAt', null)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSwitch
                      id='isEnabled'
                      name='isEnabled'
                      label={t('Enabled')}
                      value={get(values, 'isEnabled', false)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Paper>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t('Close')}</Button>
                <LoadingButton loading={isSubmitting} loadingIndicator={t('Saving')} onClick={handleSubmit}>{t('Save')}</LoadingButton>
              </DialogActions>
            </>
          )
        }
      </Formik>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
