import {
  Button,
  ButtonGroup,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  MenuItem,
  Typography,
  TextField,
  Box,
  Paper,
  IconButton,
} from '@mui/material';
import React, { useMemo, useContext, useState, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import feathers from 'services/feathers';
import usePlayerWallet from 'hooks/usePlayerWallet';
import usePlayerGameIds from 'hooks/usePlayerGameIds';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { GameManagementDialogContext } from 'features/context/gameManagementDialogContext';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Refresh as RefreshIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import CopyToClipboard from 'features/copyToClipboard/CopyToClipboard';

import {
  FormikSelect,
  FormikTextField,
} from 'features/formikControl';

function GameManagement(props) {
  const { t } = useTranslation();
  const { open, onClose, username, companyId } = useContext(GameManagementDialogContext);
  const { data: walletData, isFetching: isFetchingWallet } = usePlayerWallet(companyId, username);
  const { data: gameIdsData, isFetching: isFetchingGameIds } = usePlayerGameIds(companyId, username);
  const [showExtra, setShowExtra] = useState(false);
  const [isGameIdLoading, setIsGameIdLoading] = useState(false);
  const [isBalanceTransferLoading, setIsBalanceTransferLoading] = useState(false);
  const [selectedGameType, setSelectedGameType] = useState(null);
  const gameIdService = feathers.service('game-ids');
  const balanceTransferService = feathers.service('balance-transfers');

  const isDataReady = useMemo(() => {
    return !isFetchingWallet && !isFetchingGameIds;
  }, [isFetchingWallet, isFetchingGameIds]);

  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const selectedGame = useMemo(() => {
    if (!selectedGameType) return null;
    return gameIdsData.find((game) => game.gameType === selectedGameType);
  }, [selectedGameType, gameIdsData]);

  const inputSchema = Yup.object().shape({
    username: Yup.string().required(t('Required')),
    companyId: Yup.string().required(t('Required')),
    gameType: Yup.string().required(t('Required')),
    amount: Yup.number().required('Required').min(0.01, '>= 0.01').max(30000, '< 30000'),
    type: Yup.string().required(t('Required')).oneOf(['deposit', 'withdrawal']),
    maxIdleHours: Yup.number().optional().min(0, '>= 0').max(24, '<= 24'),
  });

  const handleGameIdAction = useCallback((action) => {
    if (!selectedGame) return;

    const actionMap = {
      release: 'lastIdReleaseRequestAt',
      refreshPassword: 'lastPasswordResetRequestAt',
      refreshBalance: 'lastBalanceCheckRequestAt',
    };

    const mappedAction = actionMap[action];
    if (!mappedAction) return;

    const { gameIdUid } = selectedGame;
    if (!gameIdUid) return;

    setIsGameIdLoading(true);

    gameIdService.patch(gameIdUid, {
      [mappedAction]: new Date(),
    }).catch((err) => {
      setGlobalErrorMessage({ err });
      setIsGameIdLoading(false);
    });

  }, [selectedGame, gameIdService, setGlobalErrorMessage]);

  const handleObtainGameId = useCallback(() => {
    if (!selectedGame) return;

    const { gameType } = selectedGame;

    if (!gameType) return;

    setIsGameIdLoading(true);

    gameIdService.create({
      gameType,
      username,
      companyId,
    }).catch((err) => {
      setGlobalErrorMessage({ err });
      setIsGameIdLoading(false);
    });
  }, [selectedGame, gameIdService, username, companyId, setGlobalErrorMessage]);

  useEffect(() => {
    if (!isGameIdLoading || !selectedGame) return;

    const { gameIdUid, gameType: selectedGameType } = selectedGame;

    const onPatched = (data) => {
      const { _id } = data;

      if (_id !== gameIdUid) return;
      setIsGameIdLoading(false);
    };

    const onCreated = (data) => {
      const { gameType, username: _username, companyId: _companyId } = data;
      if (gameType !== selectedGameType || _username !== username || _companyId !== companyId) return;
      setIsGameIdLoading(false);
    };

    const onFailed = (data) => {
      if (!data) return;

      const { gameType, username: _username, companyId: _companyId, error } = data;
      if (gameType !== selectedGameType || _username !== username || _companyId !== companyId) return;

      setGlobalErrorMessage({ err: error });
      setIsGameIdLoading(false);
    };

    gameIdService.on('patched', onPatched);
    gameIdService.on('created', onCreated);
    gameIdService.on('removed', onPatched);
    gameIdService.on('failed', onFailed);

    return () => {
      gameIdService.off('patched', onPatched);
      gameIdService.off('created', onCreated);
      gameIdService.off('removed', onPatched);
      gameIdService.off('failed', onFailed);
    };
  }, [selectedGame, username, companyId, isGameIdLoading, gameIdService, setGlobalErrorMessage]);

  useEffect(() => {
    if (!isBalanceTransferLoading || !selectedGame) return;

    const { gameType: selectedGameType } = selectedGame;

    const onPatched = (data) => {
      const { gameType, username: _username, companyId: _companyId, state } = data;

      if (gameType !== selectedGameType || _username !== username || _companyId !== companyId) return;

      if (state === 'done') {
        setGlobalMessage({ message: t('Balance transfer successful') });
      } else if (state === 'canceled') {
        setGlobalErrorMessage({ err: { message: t('Balance transfer canceled') } });
      } else if (state === 'manual') {
        setGlobalErrorMessage({ err: { message: t('Manual intervention required') } });
      }
      setIsBalanceTransferLoading(false);
    };

    const onRemoved = (data) => {
      const { gameType, username: _username, companyId: _companyId } = data;

      if (gameType !== selectedGameType || _username !== username || _companyId !== companyId) return;

      setGlobalErrorMessage({ err: { message: t('Balance transfer failed') } });
      setIsBalanceTransferLoading(false);
    };

    balanceTransferService.on('patched', onPatched);
    balanceTransferService.on('removed', onRemoved);

    return () => {
      balanceTransferService.off('patched', onPatched);
      balanceTransferService.off('removed', onRemoved);
    };
  }, [selectedGame, username, companyId, isBalanceTransferLoading, balanceTransferService, setGlobalMessage, setGlobalErrorMessage, t]);

  const handleBalanceTransfer = useCallback((values, { setFieldTouched, setFieldValue }) => {
    setIsBalanceTransferLoading(true);

    const { type, maxIdleHours, ...rest } = values;

    const data = {
      ...rest,
      type,
      maxIdleHours: type === 'deposit' ? maxIdleHours : undefined,
    };

    balanceTransferService.create(data).catch((err) => {
      setGlobalErrorMessage({ err });
      setIsBalanceTransferLoading(false);
    }).finally(() => {
      setFieldTouched('amount', false);
      setFieldValue('amount', '');
      setFieldTouched('type', false);
      setFieldValue('type', '');
    });
  }, [balanceTransferService, setGlobalErrorMessage]);

  const clipboardText = useMemo(() => {
    if (!selectedGame) return '';
    const { gameName, gameId, gamePassword, balance } = selectedGame;
    return `🎮 ${gameName}\n🆔 ${gameId}\n🔑 ${gamePassword}\n💲 ${balance}`;
  }, [selectedGame]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      {
        (!isDataReady || isGameIdLoading || isBalanceTransferLoading) && (
          <LinearProgress />
        )
      }
      <DialogTitle>
        {t('Game Management')}
      </DialogTitle>
      <DialogContent dividers>
        <Box display='flex' justifyContent={'space-between'} alignItems='center' gap={1}>
          <Typography variant='h6'>{t('User')}:</Typography>
          <Typography variant='h6'>{username}@{companyId}</Typography>
        </Box>
        <Box display='flex' justifyContent={'space-between'} alignItems='center' gap={1}>
          <Typography variant='h6'>{t('Cash Balance')}:</Typography>
          <Typography variant='h6'>{walletData?.cashBalance?.toFixed(2) || "0.00"}</Typography>
        </Box>
      </DialogContent>
      <Formik
        initialValues={{
          username: username,
          companyId: companyId,
          gameType: '',
          amount: '',
          type: '',
          maxIdleHours: 12,
        }}
        validationSchema={inputSchema}
        onSubmit={handleBalanceTransfer}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <>
            <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormikSelect
                  disabled={isGameIdLoading || isBalanceTransferLoading}
                  fullWidth
                  id='gameType'
                  name='gameType'
                  label={t('Game Type')}
                  value={values.gameType || ''}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const gameType = e.target.value;
                    handleChange(e);
                    setSelectedGameType(gameType);
                  }}
                >
                  {
                    gameIdsData.map((game) => {
                      const { _id, gameType, gameName, balance } = game;
                      return (
                        <MenuItem key={_id} value={gameType}>
                          <Box display='flex' justifyContent='space-between' sx={{ width: '100%' }} alignItems='center'>
                            <Typography variant='body2'>{gameName}</Typography>
                            <Typography variant='body2'>{balance?.toFixed(2) || "0.00"}</Typography>
                          </Box>
                        </MenuItem>
                      );
                    })
                  }
                </FormikSelect>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 2 }}>
                  <TextField
                    margin='normal'
                    fullWidth
                    id='gameBalance'
                    name='gameBalance'
                    label={t('Game Balance')}
                    value={selectedGame?.balance?.toFixed(2) || "0.00"}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          disabled={isGameIdLoading || isBalanceTransferLoading}
                          onClick={(e) => {
                            e.preventDefault();
                            handleGameIdAction('refreshBalance');
                          }}
                        >
                          <RefreshIcon />
                        </IconButton>
                      )
                    }}
                  />
                  <TextField
                    margin='normal'
                    fullWidth
                    id='gameId'
                    name='gameId'
                    label={t('Game ID')}
                    value={selectedGame?.gameId || ''}
                    InputProps={{
                      endAdornment:
                        selectedGame?.gameId
                          ? (
                            <IconButton
                              disabled={isGameIdLoading || isBalanceTransferLoading}
                              onClick={(e) => {
                                e.preventDefault();
                                handleGameIdAction('release');
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )
                          : (
                            <IconButton
                              onClick={handleObtainGameId}
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          )
                    }}
                  />
                  <TextField
                    margin='normal'
                    fullWidth
                    id='gamePassword'
                    name='gamePassword'
                    label={t('Game Password')}
                    value={selectedGame?.gamePassword || ''}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          disabled={isGameIdLoading || isBalanceTransferLoading}
                          onClick={(e) => {
                            e.preventDefault();
                            handleGameIdAction('refreshPassword');
                          }}
                        >
                          <RefreshIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Paper>
                <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
                  <Box display='flex' alignItems='center' justifyContent={'space-between'} gap={1}>
                  <Typography variant='h6'>{t('Balance Transfer')}</Typography>
                    <IconButton
                      onClick={() => setShowExtra(!showExtra)}
                    >
                      {
                        showExtra ? <ExpandLessIcon /> : <ExpandMoreIcon />
                      }
                    </IconButton>
                  </Box>
                  {
                    showExtra && (
                      <FormikTextField
                        margin='normal'
                        fullWidth
                        id='maxIdleHours'
                        name='maxIdleHours'
                        label={t('Max Idle Hours')}
                        value={values.maxIdleHours}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.maxIdleHours && touched.maxIdleHours}
                        helperText={touched.maxIdleHours && errors.maxIdleHours}
                      />
                    )
                  }
                  <FormikTextField
                    margin='normal'
                    fullWidth
                    id='amount'
                    name='amount'
                    label={t('Amount')}
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.amount && touched.amount}
                    helperText={touched.amount && errors.amount}
                  />
                  <Box display='flex' alignItems='center' justifyContent={'space-between'} gap={1}>
                    <Box display='flex' gap={1}>
                      <ButtonGroup
                        disabled={isGameIdLoading || isBalanceTransferLoading}
                      >
                        <Button
                          variant='outlined'
                          color='success'
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue('type', 'deposit');
                            setFieldValue('amount', walletData?.cashBalance || 0);
                          }}
                        >
                          {
                            t('All')
                          }
                        </Button>
                        <Button
                          variant='contained'
                          color='success'
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue('type', 'deposit').then(() => {
                              handleSubmit();
                            });
                          }}
                        >
                          {t('Balance In')}
                        </Button>
                      </ButtonGroup>
                    </Box>
                    <Box display='flex' gap={1}>
                      <ButtonGroup
                        disabled={isGameIdLoading || isBalanceTransferLoading}
                      >
                        <Button
                          variant='outlined'
                          color='error'
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue('type', 'withdrawal');
                            setFieldValue('amount', selectedGame?.balance || 0);
                          }}
                        >
                          {
                            t('All')
                          }
                        </Button>
                        <Button
                          variant='contained'
                          color='error'
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue('type', 'withdrawal').then(() => {
                              handleSubmit();
                            });
                          }}
                        >
                          {t('Balance Out')}
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('Close')}</Button>
          <CopyToClipboard text={clipboardText} copiedText={t('Game ID Copied')}>
            <Button disabled={!clipboardText}>{t('Copy Game ID')}</Button>
          </CopyToClipboard>
        </DialogActions>
        </>
      )}
      </Formik>
    </Dialog>
  );
}

export default GameManagement;