import React, { useMemo, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from 'hooks/useAuth';
import { get, startCase } from 'lodash';
import Decimal from 'decimal.js';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CashIcon from '@mui/icons-material/LocalAtmTwoTone';
import PointIcon from '@mui/icons-material/RedeemTwoTone';
import ThemeIcon from '@mui/icons-material/FormatPaintTwoTone';
import Masonry from '@mui/lab/Masonry';
import useFeathersGet from 'hooks/useFeathersGet';
import CommonContext from 'features/context/commonContext';
import ReferralInformation from './ReferralInformation';
import AccountOverview from './AccountOverview';
import RecentCashTransactions from './RecentCashTransactions';
import RecentPointTransactions from './RecentPointTransactions';
import RecentRewardBreakdown from './RecentRewardBreakdown';
import BankInformation from './BankInformation';
import UserProfile from './UserProfile';
import WageringMetrics from './WageringMetrics';
import TurnoverInfo from './TurnoverInfo';
import { UserDialogContext } from 'features/context/userDialogContext';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { lookups as lookupLocales } from 'lookups/locales';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

export default function UserDialog() {
  const { t } = useTranslation();
  const { user: authedUser } = useAuth();
  const lang = get(authedUser, 'lang', 'en');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { open, onClose, username, companyId } = useContext(UserDialogContext);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const compositeId = useMemo(
    () => {
      if (!username || !companyId) return '';
      return `${username}-${companyId}`;
    }, [username, companyId]
  );

  const { data: user, ready: userReady } = useFeathersGet('user-profiles', compositeId);
  const { memberTiers: ranks } = useContext(CommonContext);

  const isLoading = useMemo(
    () => {
      return !userReady;
    }, [userReady]
  );

  const data = useMemo(
    () => {
      const getUserData = ({
        _id: userId,
        username = '',
        name = '',
        nickname = '',
        createdAt,
        lang: language = '',
        ipLocation = {},
        lastLogon: lastLogonRaw = null,
        lastSeen: {
          line: lastSeenLineRaw = null,
          telegram: lastSeenTelegramRaw = null,
          whatsapp: lastSeenWhatsAppRaw = null
        } = {},
        settings: {
          darkMode =
          false,
          themeName = 'default'
        } = {}
      } = {}) => {
        const themeNameFormatted = startCase(darkMode ? 'night' : themeName);
        const lastLogon = lastLogonRaw ? dayjs(lastLogonRaw).locale(lang).fromNow() : null;
        const lastSeenLine = lastSeenLineRaw ? dayjs(lastSeenLineRaw).locale(lang).fromNow() : null;
        const lastSeenTelegram = lastSeenTelegramRaw ? dayjs(lastSeenTelegramRaw).locale(lang).fromNow() : null;
        const lastSeenWhatsApp = lastSeenWhatsAppRaw ? dayjs(lastSeenWhatsAppRaw).locale(lang).fromNow() : null;
        const memberSince = createdAt ? dayjs(createdAt).locale(lang).fromNow() : null;

        const { country, state, city } = ipLocation;
        const addressArray = [];
        if (country) addressArray.push(country);
        if (state) addressArray.push(state);
        if (city) addressArray.push(city);

        const address = addressArray.join(', ');
        const lookedUpLanguage = lookupLocales[language] || language;

        return { userId, username, name, nickname, language: lookedUpLanguage, address, lastLogon, lastSeenLine, lastSeenTelegram, lastSeenWhatsApp, themeName: themeNameFormatted, memberSince };
      };

      const getWalletData = ({
        _id: walletId,
        savedBank,
        cashBalance: {
          $numberDecimal: walletCashBalance = '0'
        } = {},
        pointBalance: {
          $numberDecimal: walletPointBalance = '0'
        } = {}
      } = {}) => {
        const cashBalance = new Decimal(walletCashBalance);
        const pointBalance = new Decimal(walletPointBalance);

        return { walletId, cashBalance, pointBalance, savedBank };
      };

      const getUserDataData = ({
        level = 0,
        tierId,
        refereeCount = 0,
        referralReward: {
          point: {
            amount: { $numberDecimal: referralPointRewardAmountStr = '0' } = {},
            count: referralPointRewardCount = 0 } = {},
          cash: {
            amount: { $numberDecimal: referralCashRewardAmountStr = '0' } = {},
            count: referralCashRewardCount = 0 } = {}
        } = {}
      } = {}) => {
        const tierName = ranks.find((tier) => tier._id === tierId)?.name?.[lang] ?? '';
        const referralPointRewardAmount = new Decimal(referralPointRewardAmountStr);
        const referralCashRewardAmount = new Decimal(referralCashRewardAmountStr);

        return { level, tierName, refereeCount, referralPointRewardAmount, referralPointRewardCount, referralCashRewardAmount, referralCashRewardCount };
      };

      const getUserRelationData = ({
        relation = '',
        referrerUsername = ''
      } = {}) => {
        const fullRelation = relation.split(',').join(' / ');

        return { fullRelation, referrerUsername };
      };

      const getUserStatData = ({
        cashInCount = 0,
        cashInAmount: {
          $numberDecimal: cashInAmountStr = '0'
        } = {},
        cashOutCount = 0,
        cashOutAmount: {
          $numberDecimal: cashOutAmountStr = '0'
        } = {},
        bonusCount = 0,
        bonusAmount: {
          $numberDecimal: bonusAmountStr = '0'
        } = {},
        pointIssuedCount = 0,
        pointIssuedAmount: {
          $numberDecimal: pointIssuedAmountStr = '0'
        } = {},
        pointRedeemedCount = 0,
        pointRedeemedAmount: {
          $numberDecimal: pointRedeemedAmountStr = '0'
        } = {},
        betCount = 0,
        betAmount: {
          $numberDecimal: betAmountStr = '0'
        } = {},
        totalWinLose: {
          $numberDecimal: totalWinLoseStr = '0'
        } = {},
        lastDeposit: lastDepositRaw,
        lastWithdrawal: lastWithdrawalRaw
      } = {}) => {
        const cashInAmount = new Decimal(cashInAmountStr);
        const cashOutAmount = new Decimal(cashOutAmountStr);
        const bonusAmount = new Decimal(bonusAmountStr);
        const pointIssuedAmount = new Decimal(pointIssuedAmountStr);
        const pointRedeemedAmount = new Decimal(pointRedeemedAmountStr);
        const betAmount = new Decimal(betAmountStr);
        const totalWinLose = new Decimal(totalWinLoseStr);
        const lastDeposit = lastDepositRaw ? dayjs(lastDepositRaw).locale(lang).fromNow() : null;
        const lastWithdrawal = lastWithdrawalRaw ? dayjs(lastWithdrawalRaw).locale(lang).fromNow() : null;

        const redemptionToDepositRatio = cashInAmount.gt(0)
          ? bonusAmount.dividedBy(cashInAmount) : 0;

        const cashToPointRatio = pointRedeemedAmount.gt(0)
          ? bonusAmount.dividedBy(pointRedeemedAmount) : 0;

        return {
          cashInCount, cashInAmount,
          cashOutCount, cashOutAmount,
          bonusCount, bonusAmount,
          pointIssuedCount, pointIssuedAmount,
          pointRedeemedCount, pointRedeemedAmount,
          betCount, betAmount,
          totalWinLose,
          lastDeposit, lastWithdrawal,
          redemptionToDepositRatio,
          cashToPointRatio,
        };
      }

      return {
        ...getUserData(user || {}),
        ...getWalletData(user?.wallet || {}),
        ...getUserDataData(user?.userData || {}),
        ...getUserRelationData(user?.userRelation || {}),
        ...getUserStatData(user?.userStat || {}),
      };
    }, [user, lang, ranks]
  );

  const userStatMemo = useMemo(
    () => {
      return user?.userStat;
    }, [user]
  );

  const userDataMemo = useMemo(
    () => {
      return user?.userData;
    }, [user]
  );

  return (
    <Dialog
      fullScreen={isFullscreen}
      fullWidth={true}
      maxWidth='xl'
      onClose={onClose}
      open={open}
      sx={{
      }}
    >
      <DialogTitle id='user-dialog'>
        {
          isLoading && <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        }
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{
            display: 'flex',
            gap: 1,
          }}>
            <Chip label={data.language} />
            <Chip icon={<CashIcon />} label={data.cashBalance.toFixed(2)} />
            <Chip icon={<PointIcon />} label={data.pointBalance.toFixed(2)} />
            <Chip label={t('Lvl', { level: data.level })} />
            <Chip label={data.tierName} />
            <Chip icon={<ThemeIcon />} label={data.themeName} />
          </Box>
          <Box>
            <IconButton onClick={toggleFullscreen}>
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Masonry columns={{ xs: 1, md: 2, lg: 3 }} spacing={1}>
          <UserProfile data={data}  />
          <TurnoverInfo userId={data.userId} />
          <BankInformation username={username} companyId={companyId} />
          <ReferralInformation data={data} userData={userDataMemo} />
          <AccountOverview data={data} />
          <RecentCashTransactions data={userStatMemo} />
          <RecentPointTransactions data={userStatMemo} />
          <RecentRewardBreakdown data={userStatMemo} />
          <WageringMetrics data={data} />
        </Masonry>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {t('Close')}
        </Button>
      </DialogActions>

    </Dialog>
  );
}
