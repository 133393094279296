import React, { useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import { get, kebabCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { AbilityContext } from 'casl/Can';
import UsernameMenu from 'features/usernameMenu/UsernameMenu';
import Decimal from 'decimal.js';
import { lookups as lookupsGame } from 'lookups/games';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'baseBonusLogs';
const table = createColumnHelper();

export default function BaseBonusLogs() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ confirmDialog, setConfirmDialog ] = useState({
    open: false,
    rowId: null
  });
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canDelete = ability.can('delete', _RNAME);

  const setOpenConfirmDialog = useCallback(
    (open) => {
      if (open === false) {
        setConfirmDialog({ open: false, rowId: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { username, companyId } = row;
          return { username, companyId };
        }, {
          id: 'username',
          cell: info => {
            const { username, companyId } = info.getValue();
            return (<UsernameMenu username={username} companyId={companyId} />);
          },
          header: () => t('Username'),
          footer: props => props.column.id,
        }),
        table.accessor('depositId', {
          cell: info => info.getValue(),
          header: () => t('Deposit ID'),
          footer: props => props.column.id,
        }),
        table.accessor('settingId', {
          cell: info => info.getValue(),
          header: () => t('Setting ID'),
          footer: props => props.column.id,
        }),
        table.accessor('amount', {
          cell: info => {
            const val = get(info.getValue(), '$numberDecimal', '0');
            const decVal = new Decimal(val);
            return decVal.toFixed(2);
          },
          header: () => t('Amount'),
          footer: props => props.column.id,
        }),
        table.accessor('depositAmount', {
          cell: info => {
            const val = get(info.getValue(), '$numberDecimal', '0');
            const decVal = new Decimal(val);
            return decVal.toFixed(2);
          },
          header: () => t('Deposit Amount'),
          footer: props => props.column.id,
        }),
        table.accessor('depositBaseAmount', {
          cell: info => {
            const val = get(info.getValue(), '$numberDecimal', '0');
            const decVal = new Decimal(val);
            return decVal.toFixed(2);
          },
          header: () => t('Deposit Base Amount'),
          footer: props => props.column.id,
        }),
        table.accessor('wageringMultiplier', {
          cell: info => {
            const val = get(info.getValue(), '$numberDecimal', '0');
            const decVal = new Decimal(val);
            return decVal.toFixed(2);
          },
          header: () => t('Wagering Multiplier'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { wageringContributionPolicy } = row;
          return t(`wageringContributionPolicy.${wageringContributionPolicy}`);
        }, {
          id: 'wageringContributionPolicy',
          cell: info => info.getValue(),
          header: () => t('Wagering Contribution Policy'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { wageringContributionConfig = {} } = row;
          const { slot = 0, live = 0, fishing = 0, other = 0 } = wageringContributionConfig;
          const slotDecimal = new Decimal(slot.$numberDecimal || 0);
          const liveDecimal = new Decimal(live.$numberDecimal || 0);
          const fishingDecimal = new Decimal(fishing.$numberDecimal || 0);
          const otherDecimal = new Decimal(other.$numberDecimal || 0);
          return {
            slot: slotDecimal.toNumber(),
            live: liveDecimal.toNumber(),
            fishing: fishingDecimal.toNumber(),
            other: otherDecimal.toNumber(),
          };
        }, {
          id: 'wageringContributionConfig',
          cell: info => {
            const value = info.getValue();
            return (
              <Stack spacing={1}>
                <Box>{t(`gameCategories.${'slot'}`)} - {value.slot}</Box>
                <Box>{t(`gameCategories.${'live'}`)} - {value.live}</Box>
                <Box>{t(`gameCategories.${'fishing'}`)} - {value.fishing}</Box>
                <Box>{t(`gameCategories.${'other'}`)} - {value.other}</Box>
              </Stack>
            );
          },
          header: () => t('Wagering Contribution Config'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { gameAllowedPolicy } = row;
          return t(`gameAllowedPolicy.${gameAllowedPolicy}`);
        }, {
          id: 'gameAllowedPolicy',
          cell: info => info.getValue(),
          header: () => t('Game Allowed Policy'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { gameFilters = [] } = row;

          if (!gameFilters.length) {
            return '';
          }

          const gameFiltersText = gameFilters.map((game) => {
            const gameName = get(lookupsGame, game, game);
            return gameName;
          }).join(', ');

          return gameFiltersText;
        }, {
          id: 'gameFilters',
          cell: info => info.getValue(),
          header: () => t('Game Filters'),
          footer: props => props.column.id,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang]
  );

  const handleRowDelete = (rowId) => (event) => {
    event.preventDefault();
    setConfirmDialog({
      open: true,
      rowId
    });
  };

  const onDeleteConfirmed =  async () => {
    try {
      const rowId = get(confirmDialog, 'rowId');
      await feathers.service(serviceName).remove(rowId);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  function RowActions(props) {
    const data = get(props, 'row.original');
    const rowId = get(data, '_id');

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Delete')}>
          <span>
            <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <ConfirmDialog
        title={`${t('Delete Base Bonus Log')}?`}
        open={get(confirmDialog, 'open', false)}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteConfirmed}
      >
        {t('Confirm to delete?')}
      </ConfirmDialog>
      <Filter open={openFilter} setOpen={setOpenFilter} />
      <Table
        name={t('Base Bonus Logs')}
        rname={_RNAME}
        defaultColumns={defaultColumns}
        canCreate={false}
        onCreateClick={null}
        onFilterClick={handleOnFilterClick}
        defaultColumnVisibility={{
          _id: false,
          depositId: false,
          settingId: false,
        }}
      />
    </Box>
  );
}
