import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  getFilter,
} from 'features/feathersStore/selectors';
import {
  setFilter,
  resetFilter,
} from 'features/feathersStore/actions';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import BoolToggle from 'features/boolToggle/BoolToggle';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { transformFilterFormData, transformFilter } from 'utils/form-utils';
import { useAuth } from 'hooks/useAuth';
import { getLocaleYupObject } from 'utils/yup-helper';

const _RNAME = 'baseBonusSettings';

export default function Filter(props) {
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const filter = useSelector(getFilter(_RNAME));
  const { user: { supportedLocales = ['en'] } = {} } = useAuth();

  const data = useMemo(
    () => {
      if (filter) {
        const transformedFilter = transformFilter(filter);
        return transformedFilter;
      }
      return {
      };
    }, [filter]
  );

  const dataSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    group: Yup.string().nullable(),
    name: Yup.object().shape(getLocaleYupObject(supportedLocales)),
    amount: Yup.number().nullable(),
    amountType: Yup.string().matches(/fixed|percentage/).nullable(),
    wageringMultiplier: Yup.number().nullable(),
    startsAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
    }),
    endsAt: Yup.object().shape({
      lt: Yup.date().nullable(),
    }),
    isEnabled: Yup.bool().nullable(),
    updatedAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
    createdAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      if (isEmpty(values)) return;
      const transformedVal = transformFilterFormData(values);
      dispatch(setFilter(_RNAME, transformedVal));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = (event) => {
    event.preventDefault();
    formik.resetForm();
    dispatch(resetFilter(_RNAME));
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('Filter')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='_id'
                name='_id'
                label='ID'
                value={get(formik, 'values._id', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched._id`, false) && Boolean(get(formik, `errors._id`))}
                helperText={get(formik, `touched._id`, false) && get(formik, `errors._id`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='group'
                name='group'
                label={t('Group')}
                value={get(formik, 'values.group', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.group`, false) && Boolean(get(formik, `errors.group`))}
                helperText={get(formik, `touched.group`, false) && get(formik, `errors.group`)}
              />
            </Grid>
            {
              supportedLocales.map(locale => (
                <Grid key={locale} item xs={12}>
                  <TextField
                    fullWidth
                    id={`name.${locale}`}
                    name={`name.${locale}`}
                    label={t(`name.${locale}`)}
                    value={get(formik, `values.name.${locale}`, '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.name.${locale}`, false) && Boolean(get(formik, `errors.name.${locale}`))}
                    helperText={get(formik, `touched.name.${locale}`, false) && get(formik, `errors.name.${locale}`)}
                  />
                </Grid>
              ))
            }
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='amount'
                name='amount'
                label={t('Amount')}
                value={get(formik, 'values.amount', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.amount`, false) && Boolean(get(formik, `errors.amount`))}
                helperText={get(formik, `touched.amount`, false) && get(formik, `errors.amount`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.amountType', false) && Boolean(get(formik, 'errors.amountType'))}
              >
                <InputLabel id='amount-type-select-label'>{t('Amount Type')}</InputLabel>
                <Select
                  autoWidth
                  labelId='amount-type-select-label'
                  id='amountType'
                  name='amountType'
                  value={get(formik, 'values.amountType', '')}
                  label={t('Amount Type')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value='fixed'>{t('amountType.fixed')}</MenuItem>
                  <MenuItem value='percentage'>{t('amountType.percentage')}</MenuItem>
                </Select>
                <FormHelperText>{get(formik, 'touched.amountType', false) && get(formik, 'errors.amountType')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='wageringMultiplier'
                name='wageringMultiplier'
                label={t('Wagering Multiplier')}
                value={get(formik, 'values.wageringMultiplier', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.wageringMultiplier`, false) && Boolean(get(formik, `errors.wageringMultiplier`))}
                helperText={get(formik, `touched.wageringMultiplier`, false) && get(formik, `errors.wageringMultiplier`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <BoolToggle
                    value={get(formik, 'values.isEnabled', null)}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('isEnabled', newValue)
                    }}
                  />
                }
                label={t('Enabled')}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='startsAt.$gte'
                  label={t('Starts At')}
                  value={get(formik, 'values.startsAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('startsAt.$gte', '');
                    else formik.setFieldValue('startsAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.startsAt.$gte', false) && Boolean(get(formik, 'errors.startsAt.$gte'))}
                  helperText={get(formik, 'touched.startsAt.$gte', false) && get(formik, 'errors.startsAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='endsAt.$lt'
                  label={t('Ends At')}
                  value={get(formik, 'values.endsAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('endsAt.$gte', '');
                    else formik.setFieldValue('endsAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.endsAt.$lt', false) && Boolean(get(formik, 'errors.endsAt.$lt'))}
                  helperText={get(formik, 'touched.endsAt.$lt', false) && get(formik, 'errors.endsAt.$lt')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$gte'
                  label={t('Updated From')}
                  value={get(formik, 'values.updatedAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$gte', '');
                    else formik.setFieldValue('updatedAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$gte', false) && Boolean(get(formik, 'errors.updatedAt.$gte'))}
                  helperText={get(formik, 'touched.updatedAt.$gte', false) && get(formik, 'errors.updatedAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$lt'
                  label={t('Updated To')}
                  value={get(formik, 'values.updatedAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$lt', '');
                    else formik.setFieldValue('updatedAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$lt', false) && Boolean(get(formik, 'errors.updatedAt.$lt'))}
                  helperText={get(formik, 'touched.updatedAt.$lt', false) && get(formik, 'errors.updatedAt.$lt')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$gte'
                  label={t('Created From')}
                  value={get(formik, 'values.createdAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$gte', '');
                    else formik.setFieldValue('createdAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$gte', false) && Boolean(get(formik, 'errors.createdAt.$gte'))}
                  helperText={get(formik, 'touched.createdAt.$gte', false) && get(formik, 'errors.createdAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$lt'
                  label={t('Created To')}
                  value={get(formik, 'values.createdAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$lt', '');
                    else formik.setFieldValue('createdAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$lt', false) && Boolean(get(formik, 'errors.createdAt.$lt'))}
                  helperText={get(formik, 'touched.createdAt.$lt', false) && get(formik, 'errors.createdAt.$lt')}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <Button onClick={handleReset}>{t('Reset')}</Button>
        <Button onClick={handleSave}>{t('Filter')}</Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
