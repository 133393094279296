import React, { useState, useCallback, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import { get } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';
import Decimal from 'decimal.js';
import { lookups as lookupGames } from 'lookups/games';
import UsernameMenu from 'features/usernameMenu/UsernameMenu';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'walletHistory';
const table = createColumnHelper();

export default function WalletHistory() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const [ openFilter, setOpenFilter ] = useState(false);
  const NA_AMOUNT = '-';

  const defaultColumns = useMemo(
    () => {
      return [
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { username, companyId } = row;
          return { username, companyId };
        }, {
          id: 'username',
          cell: info => {
            const { username, companyId } = info.getValue();
            return (<UsernameMenu username={username} companyId={companyId} />);
          },
          header: () => t('Username'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { __type: rowType, balanceType, type: signType } = row;
          let amountField = 'amount';
          let isNegated = false;

          switch (rowType) {
            case 'deposit':
            case 'cashrebate':
            case 'basebonuslogs':
              break;

            case 'withdrawal':
              isNegated = true;
              break;

            case 'balancetransfer':
              if (signType === 'deposit') isNegated = true;
              break;

            case 'adjustment':
              if (balanceType !== 'cash') return NA_AMOUNT;
              if (signType === 'minus') isNegated = true;
              break;

            case 'referral':
            case 'redemption':
              amountField = 'cashAmount';
              break;

            default:
              return NA_AMOUNT;
          }

          const amount = new Decimal(get(row, `${amountField}.$numberDecimal`, '0'));
          if (isNegated) return amount.negated().toFixed(2);
          return amount.toFixed(2);
        }, {
          id: 'cashAmount',
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right'
              }}
              element='span'>
              {val}
            </Box>
          },
          header: () => t('Cash Amount'),
          footer: props => props.column.id,
          enableSorting: false
        }),
        table.accessor(row => {
          const { __type: rowType, balanceType, type: signType } = row;
          let amountField = 'amount';
          let isNegated = false;

          switch (rowType) {
            case 'tier':
            case 'booster':
            case 'luckydraw':
            case 'vip':
              break;

            case 'adjustment':
              if (balanceType !== 'point') return NA_AMOUNT;
              if (signType === 'minus') isNegated = true;
              break;

            case 'referral':
              amountField = 'pointAmount';
              break;

            case 'redemption':
              amountField = 'pointAmount';
              isNegated = true;
              break;

            default:
              return NA_AMOUNT;
          }

          const amount = new Decimal(get(row, `${amountField}.$numberDecimal`, '0'));
          if (isNegated) return amount.negated().toFixed(2);
          return amount.toFixed(2);
        }, {
          id: 'pointAmount',
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right'
              }}
              element='span'>
              {val}
            </Box>
          },
          header: () => t('Point Amount'),
          footer: props => props.column.id,
          enableSorting: false
        }),
        table.accessor(row => {
          const gameType = get(row, 'gameType', '');
          return get(lookupGames, gameType, '');
        }, {
          id: 'gameType',
          cell: info => info.getValue(),
          header: () => t('Game Type'),
          footer: props => props.column.id,
          enableSorting: false
        }),
        table.accessor(row => {
          const gameId = get(row, 'gameId', '');
          return gameId;
        }, {
          id: 'gameId',
          cell: info => info.getValue(),
          header: () => t('Game Id'),
          footer: props => props.column.id,
          enableSorting: false
        }),
        table.accessor('__type', {
          id: `type`,
          cell: info => t(info.getValue()),
          header: () => t('Type'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor('state', {
          cell: info => {
            const val = info.getValue();
            const isDone = val === 'done' ? true : false;
            const isCanceled = val === 'canceled' ? true : false;
            const color = isDone ? 'success.main' : isCanceled ? 'error.main' : 'inherit';
            const fontWeight = (isDone || isCanceled) ? 700 : 'inherit';
            return <Box sx={{ fontWeight, color }} element='span'>{t(val)}</Box>
          },
          header: () => t('State'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang]
  );

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  return (
    <Box>
      <Filter open={openFilter} setOpen={setOpenFilter} />
      <Table
        name={t('Wallet History')}
        rname={_RNAME}
        defaultColumns={defaultColumns}
        canCreate={false}
        onCreateClick={null}
        onFilterClick={handleOnFilterClick}
        defaultColumnVisibility={{
          _id: false,
          gameType: false,
          gameId: false,
          updatedAt: false,
        }}
      />
    </Box>
  );
}
