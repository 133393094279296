import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import {
  EditTwoTone as EditIcon,
  DeleteTwoTone as DeleteIcon,
  ToggleOnTwoTone as ToggleOnIcon,
  ToggleOffTwoTone as ToggleOffIcon,
} from '@mui/icons-material';
import { get, kebabCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { AbilityContext } from 'casl/Can';
import Decimal from 'decimal.js';
import { lookups as lookupsGame } from 'lookups/games';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'baseBonusSettings';
const table = createColumnHelper();

export default function LuckyDrawSettings() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [ editData, setEditData ] = useState(null);
  const lang = get(user, 'lang', 'en');
  const [ openForm, setOpenForm ] = useState(false);
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ confirmDialog, setConfirmDialog ] = useState({
    open: false,
    rowId: null
  });
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canCreate = ability.can('create', _RNAME);
  const canUpdate = ability.can('update', _RNAME);
  const canDelete = ability.can('delete', _RNAME);

  useEffect(() => {
    if (!openForm) setEditData(null);
  }, [openForm]);

  const setOpenConfirmDialog = useCallback(
    (open) => {
      if (open === false) {
        setConfirmDialog({ open: false, rowId: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor('group', {
          cell: info => info.getValue(),
          header: () => t('Group'),
          footer: props => props.column.id,
        }),
        table.accessor(row => get(row, `groupLabel.${lang}`, ''), {
          id: `groupLabel.${lang}`,
          cell: info => info.getValue(),
          header: () => t('Group Label'),
          footer: props => props.column.id,
        }),
        table.accessor(row => get(row, `name.${lang}`, ''), {
          id: `name.${lang}`,
          cell: info => info.getValue(),
          header: () => t('Name'),
          footer: props => props.column.id,
        }),
        table.accessor('weight', {
          cell: info => info.getValue(),
          header: () => t('Weight'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const amount = get(row, 'amount.$numberDecimal', 0);
          const amountDecimal = new Decimal(amount);
          const amountType = get(row, 'amountType', 'fixed');
          if (amountType === 'percentage') {
            return `${amountDecimal.toFixed(2)}%`;
          } else {
            return `$${amountDecimal.toFixed(2)}`;
          }
        }, {
          id: 'amount',
          cell: info => info.getValue(),
          header: () => t('Amount'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        // minDeposit, maxDeposit, maxBonus all from $numberDecimal, use Decimal to fixed(2)
        table.accessor(row => {
          const minDeposit = get(row, 'minDeposit.$numberDecimal', 0);
          const minDepositDecimal = new Decimal(minDeposit);

          if (minDepositDecimal.isZero()) {
            return '-';
          }
          return minDepositDecimal.toFixed(2);
        }, {
          id: 'minDeposit',
          cell: info => info.getValue(),
          header: () => t('Min Deposit'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const maxDeposit = get(row, 'maxDeposit.$numberDecimal', 0);
          const maxDepositDecimal = new Decimal(maxDeposit);

          if (maxDepositDecimal.isZero()) {
            return '-';
          }
          return maxDepositDecimal.toFixed(2);
        }, {
          id: 'maxDeposit',
          cell: info => info.getValue(),
          header: () => t('Max Deposit'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const maxBonus = get(row, 'maxBonus.$numberDecimal', 0);
          const maxBonusDecimal = new Decimal(maxBonus);

          if (maxBonusDecimal.isZero()) {
            return '-';
          }
          return maxBonusDecimal.toFixed(2);
        }, {
          id: 'maxBonus',
          cell: info => info.getValue(),
          header: () => t('Max Bonus'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { availabilityType, availabilityValue, availabilityInterval } = row;
          const translatedAvailability = t(`bonusAvailability.${availabilityType}`, { count: availabilityValue, ordinal: true });

          if (availabilityInterval === 'global') {
            return translatedAvailability;
          } else {
            return `${translatedAvailability} (${t(`bonusAvailabilityInterval.${availabilityInterval}`)})`;
          }
        }, {
          id: 'availability',
          cell: info => info.getValue(),
          header: () => t('Availability'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor(row => {
          const { claimLimit, claimLimitResetPolicy } = row;

          if (!claimLimit) {
            return '';
          }

          const translatedClaimLimitResetPolicy = t(`bonusAvailabilityInterval.${claimLimitResetPolicy}`);
          return `${claimLimit} (${translatedClaimLimitResetPolicy})`;
        }, {
          id: 'claimLimit',
          cell: info => info.getValue(),
          header: () => t('Claim Limit'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor(row => {
          const { wageringMultiplier = 0 } = row;
          const wageringMultiplierDecimal = new Decimal(wageringMultiplier.$numberDecimal || 0);
          return wageringMultiplierDecimal.toNumber();
        }, {
          id: 'wageringMultiplier',
          cell: info => info.getValue(),
          header: () => t('Wagering Multiplier'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { wageringContributionPolicy } = row;
          return t(`wageringContributionPolicy.${wageringContributionPolicy}`);
        }, {
          id: 'wageringContributionPolicy',
          cell: info => info.getValue(),
          header: () => t('Wagering Contribution Policy'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { wageringContributionConfig = {} } = row;
          const { slot = 0, live = 0, fishing = 0, other = 0 } = wageringContributionConfig;
          const slotDecimal = new Decimal(slot.$numberDecimal || 0);
          const liveDecimal = new Decimal(live.$numberDecimal || 0);
          const fishingDecimal = new Decimal(fishing.$numberDecimal || 0);
          const otherDecimal = new Decimal(other.$numberDecimal || 0);
          return {
            slot: slotDecimal.toNumber(),
            live: liveDecimal.toNumber(),
            fishing: fishingDecimal.toNumber(),
            other: otherDecimal.toNumber(),
          };
        }, {
          id: 'wageringContributionConfig',
          cell: info => {
            const value = info.getValue();
            return (
              <Stack spacing={1}>
                <Box>{t(`gameCategories.${'slot'}`)} - {value.slot}</Box>
                <Box>{t(`gameCategories.${'live'}`)} - {value.live}</Box>
                <Box>{t(`gameCategories.${'fishing'}`)} - {value.fishing}</Box>
                <Box>{t(`gameCategories.${'other'}`)} - {value.other}</Box>
              </Stack>
            );
          },
          header: () => t('Wagering Contribution Config'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { gameAllowedPolicy } = row;
          return t(`gameAllowedPolicy.${gameAllowedPolicy}`);
        }, {
          id: 'gameAllowedPolicy',
          cell: info => info.getValue(),
          header: () => t('Game Allowed Policy'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { gameFilters = [] } = row;

          if (!gameFilters.length) {
            return '';
          }

          const gameFiltersText = gameFilters.map((game) => {
            const gameName = get(lookupsGame, game, game);
            return gameName;
          }).join(', ');

          return gameFiltersText;
        }, {
          id: 'gameFilters',
          cell: info => info.getValue(),
          header: () => t('Game Filters'),
          footer: props => props.column.id,
        }),
        table.accessor('startsAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Starts At'),
          footer: props => props.column.id,
        }),
        table.accessor('endsAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Ends At'),
          footer: props => props.column.id,
        }),
        table.accessor('isEnabled', {
          id: 'isEnabled',
          cell: info => {
            const val = info.getValue();
            const color = val === false ? 'error.main' : 'inherit';
            const fontWeight = val === false ? 700 : 'inherit';
            return <Box sx={{ fontWeight, color }} element='span'>{t(val)}</Box>
          },
          header: () => t('Enabled'),
          footer: props => props.column.id,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [lang, t]
  );

  const handleRowEdit = (data) => (event) => {
    event.preventDefault();
    setEditData(data);
    setOpenForm(true);
  };

  const handleRowDelete = (rowId) => (event) => {
    event.preventDefault();
    setConfirmDialog({
      open: true,
      rowId
    });
  };

  const onDeleteConfirmed =  async () => {
    try {
      const rowId = get(confirmDialog, 'rowId');
      await feathers.service(serviceName).remove(rowId);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleOnCreateClick = useCallback(
    (event) => {
      event.preventDefault();
      setEditData({});
      setOpenForm(true);
    }, []
  );

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  const handleIsEnabledToggle = useCallback((data) => async (event) => {
    event?.preventDefault();
    const { _id: rowId, isEnabled = true } = data;

    try {
      await feathers.service(serviceName).patch(rowId, {
        isEnabled: !isEnabled,
      });
      setGlobalMessage({
        message: t('Saved'),
        severity: 'success'
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  }, [serviceName, setGlobalMessage, setGlobalErrorMessage, t]);

  function RowActions(props) {
    const data = get(props, 'row.original');
    const { _id: rowId, isEnabled } = data;
    const canUpdateIsEnabled = ability.can('update', _RNAME, 'isEnabled');

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Delete')}>
          <span>
            <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('Edit')}>
          <span>
            <IconButton disabled={!canUpdate} onClick={handleRowEdit(data)} color='primary'>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={isEnabled ? t("Disable") : t("Enable")}>
          <span>
           <IconButton disabled={!canUpdateIsEnabled} onClick={handleIsEnabledToggle(data)} color='primary'>
              {
                !isEnabled ? <ToggleOffIcon color='error' /> : <ToggleOnIcon color='success' />
              }
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <ConfirmDialog
        title={`${t('Delete Base Bonus Setting')}`}
        open={get(confirmDialog, 'open', false)}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteConfirmed}
      >
        {t('Confirm to delete?')}
      </ConfirmDialog>
      <Filter open={openFilter} setOpen={setOpenFilter} />
      { editData && <Form open={openForm} setOpen={setOpenForm} data={editData} /> }
      {
        !!defaultColumns.length &&
        <Table
          name={t('Base Bonus Settings')}
          rname={_RNAME}
          defaultColumns={defaultColumns}
          canCreate={canCreate}
          onCreateClick={handleOnCreateClick}
          onFilterClick={handleOnFilterClick}
          defaultColumnVisibility={{
            _id: false,
            [`groupLabel.${lang}`]: false,
            gameAllowedPolicy: false,
            gameFilters: false,
            startsAt: false,
            endsAt: false,
            updatedAt: false,
            createdAt: false,
          }}
        />
      }
    </Box>
  );
}
